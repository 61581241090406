import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import { Button, Container, Form, Nav, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
// Images
import reviewImg from "../../../images/home/property-rating-img.png"
import bgCurve from "../../../images/home/pg-logo-curve.png"
// styles
import disImg1 from "../../../images/home/discover-img01.jpg"
import disImg2 from "../../../images/home/discover-img02.jpg"
import disImg3 from "../../../images/home/discover-img03.jpg"
import ContactCard from "../ContactCard/ContactCard"
import "./ProjectRating.scss"
// markup
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollContainer from 'react-indiana-drag-scroll'

import { addCommas, stb_sale_link, stb_rent_link, stb_commercial_link } from "../../common/utils"

import axios from "axios"

import {NewProjectSale} from "../../../queries/common_use_query"

const ProjectRating = (props) => {

    const [propItemsSale, setPropItemsSale] = useState();
    const [propItemsRent, setPropItemsRent] = useState();

    const areaName = props.Area_Name.replace(" City", "");

    // const areaName = props.Area_Name;


    const sale =  NewProjectSale(areaName, "Sale");
    //console.log(sale.data?.newProjects, "sale")
    const rent =  NewProjectSale(areaName, "Rent");
    //console.log(rent.data?.newProjects, "rent")

    
    const projectForSale = sale.data?.newProjects;
    const projectForRent = rent.data?.newProjects;

    var settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className={`market-analysis project-analysis new_projectBanner ${((projectForSale && projectForSale?.length > 0) || (projectForRent && projectForRent?.length > 0)) ? '' : 'd-none'}`}>
            <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
            <Container>
                 <div className="animated">
                {               
                    <div className="discover-range d-md-block">
                        {
                            props.Heading && (
                                <h2 className="text-center">{props.Heading}</h2>
                            ) 
                        }
                        
                        {
                            ((projectForSale && projectForSale.length > 0) || (projectForRent && projectForRent.length > 0)) && 
                            <Tab.Container id="left-tabs-example" defaultActiveKey={projectForSale?.length > 0 ? "0" : "1"}>
                            <Nav variant="pills">
                                <div className="pills-wrap">
                                     <div className="animated">
                                    <ScrollContainer className="scroll-container">
                                        <>
                                        {
                                            projectForSale && projectForSale.length > 0 && 
                                            <Nav.Item>
                                                <Nav.Link eventKey="0">New Projects for Sale</Nav.Link>
                                            </Nav.Item>
                                        }
                                        {
                                            projectForRent && projectForRent.length > 0 &&     
                                            <Nav.Item>
                                                <Nav.Link eventKey="1">New Projects for Rent</Nav.Link>
                                            </Nav.Item>
                                        }   
                                            
                                        </>
                                        </ScrollContainer>
                                     </div>
                                </div>
                            </Nav>
                            
                             <div className="animated">
                                <Tab.Content>
                                    {
                                        <Tab.Pane eventKey="0" className="text-center">
                                            
                                            <Slider {...settings} >
                                                {
                                                    projectForSale?.map((item, index) => {

                                                        var url = "/qatar-property-services/buy-property/view-for-our-latest-projects";
                                                        var tag = "";

                                                        var urlLink = `${url}/${item.URL}`;
                                                        return (
                                                            <ContactCard
                                                                cardImg={item?.Title_Image}
                                                                cardTitle={item.Name+' in '+ props.Area_Name}
                                                                date={`${addCommas(item.Price_Starting_From)}`}
                                                                cardTag={tag}
                                                                imagetransforms={item.imagetransforms}
                                                                cardUrl={urlLink}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Slider>                                        
                                        </Tab.Pane>
                                    }

                                    {
                                        <Tab.Pane eventKey="1" className="text-center">

                                            <Slider {...settings} >
                                                {
                                                    projectForRent?.map((item, index) => {

                                                        var url = "/qatar-property-services/rent-a-property/view-for-our-latest-projects-rent";
                                                        var tag = "";

                                                        var urlLink = `${url}/${item.URL}`;
                                                        return (
                                                            <ContactCard
                                                                cardImg={item?.Title_Image}
                                                                cardTitle={item.Name+' in '+ props.Area_Name}
                                                                date={`${addCommas(item.Price_Starting_From)}`}
                                                                cardTag={tag}
                                                                imagetransforms={item.imagetransforms}
                                                                cardUrl={urlLink}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Slider>
       
                                        </Tab.Pane>
                                    }
                                    
                                </Tab.Content>
                             </div>
                        </Tab.Container>
                        } 
                        
                    </div>
                }
                 </div> 
            </Container>
        </div>
    )
}

export default ProjectRating
