import React,{useEffect, useState, createRef} from "react"
import { Helmet } from "react-helmet";
import {Container, Row, Col,Badge} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLParser from 'html-react-parser';
import axios from "axios"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Home/Banner/Banner.js";
import ContactInfo from "../components/Home/ContactInfo/ContactInfo";
import TilesBlock from "../components/Home/ContactInfo/TileBlock";
import ProjectRating from "../components/Home/ProjectRating/ProjectRating_Communities"
import NewProjectRating from "../components/Home/ProjectRating/ProjectRating_NewProjects"
import Reviews from "../components/Home/Reviews/Reviews"
import GoogleSingleMap from "../components/maps/google/community_map"
import CountSection from "../components/CountSection/CountSection"
import LatestBlogs from "../components/Home/LatestBlogs/LatestBlogs"

import '../scss/custom.scss';
import LoaderLogo from '../images/loader_logo_new.svg';

import {CommunityDetails, CommunityDistrict} from "../queries/common_use_query"

function Community_Details(props){

    const find_more_ref = createRef();

    let path_name = (props.location.pathname).split('/');
    let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];


    const [details, setDetails] = useState('');

    const {loading:loading, error:error, data:data} = CommunityDetails(get_url);
    const {loading:district_loading, error:district_error, data:district_data} = CommunityDistrict(get_url);

    // console.log ("datass",data)

    const [testimonials, setTestimonials] = useState([]);

    const findoutMore = () => {
        find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const getitems = async url => {
        try {
        const { data } = await axios.get(url,strapiconfig)// could be from env files
        setTestimonials(data)
        } catch (error) {
        // cache it if fail/error;
        console.error(error)
        }
    }
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
        getitems(url);
    }, []);

    useEffect(() => {
        data && data.communities.length > 0 && setDetails(data.communities[0]);
    },[data]);

    useEffect(() => {
        district_data && district_data.communityDistricts.length > 0 && setDetails(district_data.communityDistricts[0]);
    },[district_data]);

    var LayoutName = details && details.__typename === "CommunityDistricts" ? 'Buy_Landing_Page comm_dist_det' : 'Buy_Landing_Page';

    var location = details.Location ? ', '+details.Location : '';
    // console.log("details", details);

    let processedImages = JSON.stringify({}), image_name='';
    if (details && details.imagetransforms && details.imagetransforms.Banner_Image_Transforms) {
        processedImages = details.imagetransforms.Banner_Image_Transforms;

        image_name = "communities.Banner_Image.details";
    } else{
        image_name = "community-districts.Banner_Image.details";
    }

    console.log("details===", details);

    return(
    <Layout Layout={LayoutName} classNames={LayoutName} popular_search={details?.popular_search_communities?.length > 0 && details?.popular_search_communities[0]}>
        {details && <SEO title={details?.community?.Name ? details.Meta_Title+', '+details?.community?.Name : details.Meta_Title} description={details.Meta_Description} /> }

        <Helmet bodyAttributes={{class: `homepage`}}/>

        {(loading || district_loading) ? (
            // <div className="main-banner no_lines">
            //     <div className="loader_div">
            //         {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
            //     </div>
            // </div>
            <section className={"loader-wrapper"}>
                <div id="loader-wrapper">
                    <div id="loader" class="new-loader">
                        <div className="new-loader-anime"></div>
                        <img className="logo-white" src={LoaderLogo} alt="logo"/>
                    </div>
                    <div class="loader-section section-left"></div>
                    <div class="loader-section section-right"></div>
                </div>
            </section>
        ) : details ? <>
            {details.Banner_Image && details.Banner_Image.url &&
                <Banner Banner_Title={details.Name+location} CTA_1_Label={''}  CTA_2_Label={''} Banner_Image = {details.Banner_Image} Content = {details.Content} Layout={LayoutName} SearchText={details.Name}  imagename={image_name} article_id={details.id} imagetransforms={processedImages} handleClick={findoutMore} template={"community"} />
            }

            <div ref={find_more_ref}></div>

            {/* ======= Intro Section ====== */}
            {details.Intro_Content && details.Intro_Content.length > 0 &&
                <ContactInfo Name={details.Name} Title={details.Intro_Content[0].Intro_Title} Content={details.Intro_Content[0].Intro_Text} Office_Details={details.Intro_Content[0].Choose_Office && details.Intro_Content[0].Choose_Office} AccordionItems={details.Accordion_Content && details.Accordion_Content.Add_Accor_Item.length > 0 && details.Accordion_Content.Add_Accor_Item} Accordian_Title={details.Intro_Content[0].Intro_Accordian_Title} Accordian_List={details.Intro_Content[0].Intro_Accordian_List} Services_Title={details.Intro_Content[0].Intro_Services_Title} Services_List={details.Intro_Content[0].Intro_Services_List} Service_Text={details.Intro_Content[0].Service_Text} Service_Icon={details.Intro_Content[0].Service_Icon} Collection="Community" classNames="grey_bg" Tile_Blocks={details.Community_Districts}/>
            }
             {/* ======= Featured Properties ====== */}
             {details.Show_NewProject_Property && details.Show_NewProject_Property.Publish && <NewProjectRating Heading={details.Show_NewProject_Property.Title && details.Show_NewProject_Property.Title} CommunityTab={false} Area_Name={details.Name} /> }

            {details.__typename === "CommunityDistricts" && details.Name && 
                // <CountSection Counter_Block={details.Show_Counter_Block.Counter_Block} Counter_Title={details.Show_Counter_Block.Title} Area_Name={details.Name} />
                <CountSection  district= {district_data.communityDistricts[0].community.Name} Area_Name={details.Name.replace('Commercial','').replace('Residential','')} get_url={get_url}/>
            }

            {/* ======= Tiles  Section ====== */}
            {details.Community_Districts && details.Community_Districts.length > 0 &&
                <TilesBlock Tile_Blocks={details.Community_Districts} Collection="Community" Name={details.Name} />
            }

            {/* ======= Map Section ====== */}
            {
                details.Latitude && details.Longitude &&
                <GoogleSingleMap lat={parseFloat(details.Latitude)} lng={parseFloat(details.Longitude)} height={"650px"}/>
            }


            {/* ======= Featured Properties ====== */}
            {details.Show_Property && details.Show_Property.Show_Property && <ProjectRating Heading={details.Show_Property.Title && details.Show_Property.Title} CommunityTab={false} Area_Name={details.Name} /> }

            {/* ======= Google Reviews ====== */}
            {details.Show_Google_Reviews && details.Show_Google_Reviews.Show_Google_Review && <div className="d-md-flex flex-wrap"> <Reviews testimonials={testimonials} /> </div> }

            {/*======= Latest Blogs Insights-Case Studies ====== */}
            {details.Show_Insights && details.Show_Insights.Show_Insights &&  details.Show_Insights.Filter_by_Tag &&
              <div className="d-md-flex flex-wrap">
              <LatestBlogs  Filter_by_Tag={details.Show_Insights.Filter_by_Tag} isCommunity/> </div> 
            }
            <div className="mt-5 mb-5"></div>
        </> : <div className="main-banner no_lines">
                <div className="loader_div">
                    {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
                </div>
            </div>
        }
    </Layout>
    )
}

export default Community_Details
