import { Link } from "@StarberryUtils";
import React from "react"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"
import QuestionIcon from "../../images/question-icon.svg"
import "./CountSection.scss"


import { useStaticQuery, graphql } from "gatsby"

import {CommunityCounterSale} from "../../queries/common_use_query"



const CountSection = props => {

  //console.log("Area_Name", props.Area_Name);
  const {loading:commu_count_loading, error:commu_count_error, data:commu_count_data} = CommunityCounterSale(props.Area_Name);

  // const data = useStaticQuery(graphql`
  //   query CounterQuery {
  //     glstrapi {
  //       globalModule {
  //         District_Stats_Block {
  //           Counter_Block {
  //             id
  //             Name
  //             Count
  //             CTA_Link
  //             CTA_Label
  //           }
  //           Title
  //           id
  //         }
  //       }
  //     }
  //   }
  // `)

  // var counter_data = data.glstrapi.globalModule.District_Stats_Block;

  // console.log("commu_count_data", commu_count_data);
  // console.log("countsectionprops",props);

  return (
    
    commu_count_loading ? '' : commu_count_data && ((commu_count_data.newProjectsSale && commu_count_data.newProjectsSale.length > 0) || (commu_count_data.propertiesSale && commu_count_data.propertiesSale.length > 0) || (commu_count_data.newProjectsRent && commu_count_data.newProjectsRent.length > 0) || (commu_count_data.propertiesRent && commu_count_data.propertiesRent.length > 0)) ? <>
    <div className="count-section">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="count-section-title">
               <div className="animated">
                <h2>The Pearl Gates showcase a large variety of property in this {props.Area_Name},{props.district} community. Browse through our exclusive collection and get in touch with our friendly team.
                </h2>
               </div>
            </div>
            <div className="count-section-box">
              {/* {
                props.Counter_Block && props.Counter_Block.length > 0 && props.Counter_Block.map((item, index) => {
                  return(
                     <div className="animated" delay={150 + (index*100)} >
                      <div className="count-section-item">
                        <div className="">
                          <strong>{item.Count}</strong>
                          <p>{item.Name}</p>
                          <Link to={item.CTA_Link && item.CTA_Link} className="tracking-btn">{item.CTA_Label}</Link>
                        </div>
                      </div>
                     </div>
                  )
                })
              } */}
              
              {
                commu_count_data.newProjectsSale && commu_count_data.newProjectsSale.length > 0 &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{commu_count_data.newProjectsSale.length}</strong>
                      <p>New Projects for Sale</p>
                      <Link to={`/qatar-property-services/buy/view-for-our-latest-projects#${commu_count_data.newProjectsSale[0].Name.replace(/ /g,'-')}`} className="tracking-btn">View Projects</Link>
                    </div>
                  </div>
                 </div>
              }

              {
                commu_count_data.propertiesSale && commu_count_data.propertiesSale.length > 0 &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{commu_count_data.propertiesSale.length}</strong>
                      <p>Properties for sale in {props.Area_Name},{props.district}</p>
                      <Link to={`/properties/for-sale/in-${props.get_url.replace('-commercial','').replace('-residential','')}`} className="tracking-btn">View Properties</Link>
                    </div>
                  </div>
                 </div>
              }

              {
                commu_count_data.newProjectsRent && commu_count_data.newProjectsRent.length > 0 &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{commu_count_data.newProjectsRent.length}</strong>
                      <p>New Projects for Rent</p>
                      <Link to={`/qatar-property-services/rent/view-for-our-latest-projects-rent#${commu_count_data.newProjectsRent[0].Name.replace(/ /g,'-')}`} className="tracking-btn">View Projects</Link>
                    </div>
                  </div>
                 </div>
              }

              {
                commu_count_data.propertiesRent && commu_count_data.propertiesRent.length > 0 &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{commu_count_data.propertiesRent.length}</strong>
                      <p>Properties for Rent in {props.Area_Name},{props.district}</p>
                      <Link to={`/properties/for-rent/in-${props.get_url.replace('commercial','').replace('residential','')}`} className="tracking-btn">View Properties</Link>
                    </div>
                  </div>
                 </div>
              }

            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </> : ''
  )
}

export default CountSection
