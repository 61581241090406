import React, {useState, useEffect} from "react"

import { Link } from "@StarberryUtils";
// Images
// styles
import "./ContactCard.scss"
import { propTypes } from "react-bootstrap/esm/Image";
// markup
import PlayVideo from "../Play/PlayVideo";
import {ImageModule} from "../../modules/Image_Module";
import Calendly from "../../modules/calendly-link";
import {youtube_parser} from "../common/utils";
import ReactPlayer from 'react-player'

const ContactCard = (props) => {
  const [isPlay, setPlay] = useState(false)

  var youtube_url = props.embed_video_url && youtube_parser(props.embed_video_url);
  var calendly_link = props.calendly_link;

  let processedImages = JSON.stringify({});
  if (props.imagetransforms && props.imagetransforms.Tile_Image_Transforms) {
      processedImages = props.imagetransforms.Tile_Image_Transforms;
  } else if(props.imagetransforms && props.imagetransforms.Image_Transforms){
    processedImages = props.imagetransforms.Image_Transforms;
  }

  //console.log("insight_card", props)

  var get_url_ext = props.cardImg && props.cardImg.url.split('.').pop();


  return (
    props.page === "our_people" ? (
      <>
        <div className="contact-card our_people">
          
            <figure>
              <Link to={`/about/our-people/${props.link_url}`}>
                {props.cardImg && 
                get_url_ext === "mp4" ? (
                  <ReactPlayer className="react_player" url={props.cardImg.url} modestbranding="1" controls={false} autoplay={true} playsinline={true} loop={true} playing={true} muted={true} width='100%' height='100%' />
                ) : ( 
                  props.cardImg.url && <ImageModule ImageSrc={props.cardImg} imagename={props.imagename} article_id={props.article_id} imagetransforms={processedImages} />
                ) 
                }
              </Link>
              {/* {
                calendly_link ? (
                  <Calendly url={ calendly_link }/>
                ) : youtube_url ? (
                  <span class="btn-play" onClick={props.handleClick}><i class="icon-play"></i></span>
                ) : ('')
              } */}
            </figure>
            <Link to={`/about/our-people/${props.link_url}`}>
              <div className="info">
                  <h6 className="card-title">{props.cardTitle}</h6>
                  <span className="date">{props.date}</span>
              </div>
            </Link>
        </div>        
      </>
    ) : (
      <div className="contact-card" >
          {
            props.externalLink ? (
              props.section === "latest_blogs" ? <>
                  <figure>
                    {props.cardImg && props.cardImg.url && 
                    <ImageModule ImageSrc={props.cardImg} imagename={props.imagename} article_id={props.article_id} imagetransforms={processedImages} /> }
                    {props.cardTag && <span className="card-tag">{props.cardTag}</span> }
                  </figure>
                <Link to={props.externalLink} target="_blank">
                  <div className="info">
                      <h6 className="card-title">{props.cardTitle}</h6>
                      <span className="date">{props.date}</span>
                  </div>
                </Link>
              </> : (
                <Link to={props.externalLink} target="_blank"> 
                  <figure>
                    {props.cardImg && props.cardImg.url && 
                    <ImageModule ImageSrc={props.cardImg} imagename={props.imagename} article_id={props.article_id} imagetransforms={processedImages} /> }
                    {props.cardTag && <span className="card-tag">{props.cardTag}</span> }
                  </figure>

                  <div className="info">
                      <h6 className="card-title">{props.cardTitle}</h6>
                      <span className="date">{props.date}</span>
                  </div>
                </Link>
              )              
            ) : (
              <Link to={`/about/case-studies-and-insights/${props.link_url}`}> 
                <figure>
                  {props.cardImg && props.cardImg.url && 
                  <ImageModule ImageSrc={props.cardImg} imagename={props.imagename} article_id={props.article_id} imagetransforms={processedImages} /> }
                  {props.cardTag && <span className="card-tag">{props.cardTag}</span> }
                </figure>

                <div className="info">
                    <h6 className="card-title">{props.cardTitle}</h6>
                    <span className="date">{props.date}</span>
                </div>
              </Link>
            )
          }

      </div>
    )
  )
}

export default ContactCard